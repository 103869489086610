<template>
  <div class="center">
    <div class="search">
      <div class="ing pointer" @click="switchto">
        <div :style="{ color: isaction ? '#44A7FC' : '#000' }">进行中</div>
        <div :class="isaction ? 'action' : 'notaction'"></div>
      </div>
      <div class="ed pointer" @click="switchto">
        <div :style="{ color: isaction ? '#000' : '#44A7FC' }">其他</div>
        <div :class="isaction ? 'notaction' : 'action'"></div>
      </div>
    </div>
    <div class="list">
      <div
        :key="item.serviceOrderTransportRelationId"
        class="table"
        v-for="item in tableDate"
      >
        <div class="top">
          <div style="color: #333333">
            订单号：{{ item.orderNo }} &nbsp;&nbsp;|&nbsp;&nbsp; 下单人：{{
              item.consumerName
            }}
            &nbsp;&nbsp; {{ item.consumerTelephone }}
          </div>
          <div>
            <CountDown
              v-if="item.transportOrderStatus == 0"
              style="color: red; margin-right: 10px"
              @getList="getList"
              :endDate="`${item.timestamp}`"
            ></CountDown>
            <i class="hy-icon-shijian1"></i> {{ item.createTime }}
            <span class="status">{{
              transportOrderStatus[item.transportOrderStatus]
            }}</span>
          </div>
        </div>
        <div class="bottom">
          <div class="left pointer" @click="todetail(item.serviceOrderId)">
            <div class="img">
              <el-image
                style="width: 100%; height: 100%"
                :src="item.mainGraph"
              />
            </div>
            <div class="text">
              
              <div class="first f-hide" style="width:400px">{{ item.name }}</div>
              <div class="two">货物名称：{{ item.goodName }}</div>
              <div class="two">价格/吨：{{ item.price }}元/吨</div>
              <div class="two">发货量：{{ item.num }}吨</div>
            </div>
          </div>
          <div class="middle">
            <span class="one">订单金额： </span><span class="two">¥</span
            ><span class="three">{{ item.allprice }}</span>
          </div>
          <div class="right">
            <el-button
              v-if="item.transportOrderStatus == 0"
              type="primary"
              @click="changestatus(2, item.serviceOrderId)"
              plain
              size="mini"
              >拒绝接单</el-button
            >
            <el-button
              v-if="item.transportOrderStatus == 0"
              type="primary"
              @click="changestatus(1, item.serviceOrderId)"
              plain
              size="mini"
              >确认接单</el-button
            >
            <el-button
              v-if="
                item.transportOrderStatus == 1 || item.transportOrderStatus == 2
              "
              type="primary"
              plain
              size="mini"
              @click="
                uploadCar(
                  item.serviceOrderId,
                  item.serviceOrderTransportRelationId
                )
              "
              >上传车辆信息</el-button
            >
            <el-button
              v-if="
                item.transportOrderStatus == 2 || item.transportOrderStatus == 7
              "
              type="primary"
              @click="querycar(3, item.serviceOrderId, false)"
              plain
              size="mini"
              >确认服务完成</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="allpage">
      <el-pagination
        background
        :total="total"
        :page-size="formInline.pageSize"
        :current-page="formInline.pageNum"
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
    <el-dialog
      class="zyq_dialog"
      title="上传运输车辆信息"
      :visible.sync="dialogVisible"
      width="661px"
      :before-close="handleClose"
    >
      <div class="dialog_center">
        <el-form
          :model="form"
          ref="dynamicValidateForm"
          label-width="80px"
          class="demo-dynamic"
        >
          <el-form-item
            v-for="(domain, index) in form.domains"
            label="车牌号："
            :key="domain.key"
            :prop="'domains.' + index + '.carnum'"
            :rules="[
              {
                required: true,
                message: '车牌号不能为空',
                trigger: 'change',
              },
              {
                pattern: regex,
                message: '车牌号格式不正确',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="domain.carnum"
              placeholder="请输入车牌号"
              maxlength="8"
              size="small"
            ></el-input>
            <el-button
              v-show="index == form.domains.length - 1"
              style="margin-left: 12px"
              type="primary"
              icon="el-icon-plus"
              plain
              size="small"
              @click="addfrom"
              >添加车辆</el-button
            >
            <el-button
              v-show="index < form.domains.length - 1"
              style="margin-left: 12px"
              icon="el-icon-delete"
              plain
              size="small"
              @click="delfrom(domain)"
              >删除车辆</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  listServiceOrder,
  dealServiceOrder,
  uploadTransportCars,
  listTransportCars,
} from "@/api/home";

import CountDown from "@/components/datedown.vue";
const { baseUrl } = require("@/config/index.js");
let uploadfoo = null;
import * as types from "@/store/action-types";
import Vue from "vue";
export default {
  data() {
    return {
      regex:
        /(^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$)/,
      transportOrderStatus: [
        "未接单",
        "待付款",
        "已付款",
        "已完成",
        "已拒绝",
        "已取消",
        "等待对方确认",
        "对方已确认",
      ],
      tableDate: [],
      form: {
        domains: [
          {
            carnum: "",
            key: Date.now(),
          },
        ],
      },
      dialogVisible: false,
      isaction: true,
      total: 0,
      size: 4,
      formInline: {
        providerId: "",
        dictId: "506",
        pageSize: 3,
        orderStatus: 1,
        pageNum: 1,
      },
    };
  },
  mounted() {
	this.formInline.providerId = Vue.ls.get(types.enterpriseId);
    this.getList();
  },
  components: {
    CountDown,
  },
  methods: {
    async querycar(operateType, serverOrderId,other) {
      if (!this.beforhandle(['personalCenter:logisticsOrder:ongoing:taking','personalCenter:logisticsOrder:ongoing:refuse','personalCenter:logisticsOrder:ongoing:confirm'][operateType-1])) return;
      let foo = { serviceOrderId: serverOrderId, operateType }
      other == 201 ? foo.confirmCar = true : other == 202 ? foo.confirmPay = true : ''
      dealServiceOrder(foo)
      .then(
        (res) => {
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.getList();
          } else if (res.code == 201||res.code == 202) {
            this.$confirm(res.message, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              customClass: "hyxy-confirm",
            })
            .then(e=>{
              this.querycar(operateType,serverOrderId,res.code,true)
            })
            .catch(e=>{
              this.$message({
                type: "info",
                message: "已取消操作",
              });
            })
          }
        }
      );
    },
    ifbige(operateType, serviceOrderId, item) {
      console.log(item.allprice, Number(item.orderPayAmount));
      if (Number(item.orderPayAmount) < item.allprice) {
        this.$confirm("支付金额小于订单金额,确认要将订单完成吗", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          customClass: "hyxy-confirm",
        })
          .then((e) => {
            dealServiceOrder({
              serviceOrderId,
              operateType,
            }).then((res) => {
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功",
                });
                this.getList();
              }
            });
          })
          .catch((e) => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      } else {
        dealServiceOrder({
          serviceOrderId,
          operateType,
        }).then((res) => {
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.getList();
          }
        });
      }
    },
    handleSure() {
      console.log(uploadfoo);
      this.$refs.dynamicValidateForm.validate((valid) => {
        if (valid) {
          uploadfoo.carNums = this.form.domains.map((item) => {
            return item.carnum;
          });
          uploadTransportCars(uploadfoo).then((res) => {
            if (res.code == 0) {
              this.$message.success("上传成功");
              this.handleClose();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    uploadCar(serviceOrderId, serviceOrderTransportRelationId) {
       if (!this.beforhandle('personalCenter:logisticsOrder:ongoing:car')) return;
      uploadfoo = {
        serviceOrderId,
        serviceOrderTransportRelationId,
      };
      this.dialogVisible = true;
    },
    changestatus(operateType, serviceOrderId) {
      if (!this.beforhandle(['personalCenter:logisticsOrder:ongoing:taking','personalCenter:logisticsOrder:ongoing:refuse','personalCenter:logisticsOrder:ongoing:confirm'][operateType-1])) return;
      const status = [
        "",
        "确认接单",
        "确认拒绝接单",
        "确认服务完成",
        "确认服务完成",
      ];
      this.$confirm(status[operateType], "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "zep-confirm",
      })
        .then(() => {
          dealServiceOrder({ serviceOrderId, operateType }).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    async getList() {
      const res = await listServiceOrder(this.formInline);
      if (res.code == 0) {
        this.tableDate = res.data.list.map((item) => {
          item.serviceOrderVO.createTime =
            item.serviceOrderVO.createTime.replace("T", " ");
          return {
            ...item.serviceOrderVO,
            mainGraph:
              baseUrl +
              "third/third/ossGetFile/" +
              item.serviceOrderVO.mainGraph,
            ...item.transportInfo,
            allprice:
              (item.transportInfo.num *
                100 *
                (item.transportInfo.price * 100)) /
              10000,
            timestamp: this.datacomponent(item.serviceOrderVO.createTime),
          };
        });
        console.log(this.tableDate);
        this.total = res.data.total;
      }
    },
    datacomponent(date) {
	  //ie只支持"2019/04/26 12:23:22"格式字符串转时间不支持"2022-08-26 00:00:00"
      let createTime = new Date(Date.parse(date.replace(/-/g,"/"))).getTime() + 10800000;
      let timestamp = new Date().getTime();
      return createTime - timestamp;
    },
    handleCurrentChange(value) {
      this.formInline.pageNum = value;
      console.log(this.formInline);
      this.getList();
    },
    todetail(id) {
      if (!this.beforhandle('personalCenter:logisticsOrder:ongoing:detail')) return;
      this.$router.push({
        path: "/logisticsdetail",
        query: {
          id,
        },
      });
    },
    switchto() {
      this.isaction = !this.isaction;
      if (this.isaction) {
        this.formInline.orderStatus = 1;
      } else {
        this.formInline.orderStatus = 2;
      }
      this.formInline.pageNum = 1;
      this.getList();
    },
    addfrom() {
      this.form.domains.push({
        carnum: "",
        key: Date.now(),
      });
    },
    delfrom(delfrom) {
      let index = this.form.domains.indexOf(delfrom);
      if (index !== -1) {
        this.form.domains.splice(index, 1);
      }
    },
    handleClose() {
      this.form = {
        domains: [
          {
            carnum: "",
            key: Date.now(),
          },
        ],
      };
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss">
.zep-confirm {
  .el-message-box {
    width: 450px !important;
    height: 218px !important;
    background: #ffffff !important;
    border-radius: 10px !important;
  }
  .el-message-box__header {
    background: #f8f8f8;
  }
  .el-message-box__title {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
  }
  .el-message-box__content {
    padding: 49px 119px;
    color: #606266;
    font-size: 14px;
  }
  .el-message-box__btns {
    .el-button--primary {
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
    }
  }
}
</style>
<style lang="scss">
.zyq_dialog {
  .el-dialog {
    border-radius: 10px;
  }
  .el-dialog__header {
    height: 53px;
    padding: 19px 25px;
    background: #f8f8f8;
    border-radius: 10px;
    .el-dialog__title {
      font-size: 16px;
      line-height: 16px;
      color: #666666;
    }
  }
  .el-dialog__footer {
    padding: 18px 25px 25px;
    .el-button--primary {
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
    }
  }
  // input[type=text] {
  //   height: 34px;
  // }
  .demo-dynamic {
    // width: 407px;
  }
  .el-input {
    width: 211px;
  }
  .dialog_center {
    display: flex;
    justify-content: center;
    height: 300px;
    overflow-y: auto;
  }
}
</style>
<style lang="scss" scoped>
.center {
  width: 1195px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: -1px 0px 10px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  .search {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 800;
    .action {
      width: 33px;
      height: 3px;
      margin-top: 3px;
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
      border-radius: 2px;
    }
    .notaction {
      width: 33px;
      height: 3px;
      margin-top: 3px;
      border-radius: 2px;
    }
    .ing {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 50%;
      height: 100%;
    }
    .ed {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 50%;
      height: 100%;
      border-left: 1px solid #e5e5e5;
    }
  }
  .list {
    padding: 16px;
    padding-bottom: 0px;
    .table {
      height: 162px;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
      margin-bottom: 16px;
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 43px;
        padding: 0 16px;
        border-bottom: 1px dashed #e5e5e5;
        color: #999999;
        .status {
          padding-left: 41px;
          color: #0a9d41;
        }
      }
      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 118px;
        padding: 0 8px;
      }
      .left {
        display: flex;
        .img {
          width: 167px;
          height: 97px;
          border-radius: 2px;
          overflow: hidden;
        }
        .text {
          margin-left: 17px;
          .first {
            height: 20px;
            line-height: 20px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #384048;
            margin-top: 4px;
            margin-bottom: 20px;
          }
          .two {
            height: 14px;
            line-height: 14px;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #999999;
            margin-bottom: 4px;
          }
        }
      }
      .middle {
        .one {
          font-size: 12px;
          color: #6d6b6b;
        }
        .two {
          font-size: 16px;
          color: #d0111a;
        }
        .three {
          padding-left: 4px;
          font-size: 28px;
          font-weight: 600;
          color: #d0111a;
        }
      }
      .right {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: flex-end;
        height: 80px;
        padding-right: 17px;
        min-width: 120px;
        .status {
          padding: 4px 13px;
          height: 19px;
          line-height: 19px;
          font-size: 12px;
          color: #fff;
          background: #f87b28;
          border-radius: 10px;
        }
        .do {
          width: 127px;
          height: 29px;
          line-height: 29px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #b3d8ff;
          border-radius: 4px;
        }
      }
    }
  }
  .allpage {
    text-align: right;
    margin-bottom: 20px;
    margin-right: 6px;
  }
}
</style>>